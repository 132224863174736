<template>
  <div v-if="loading" class="loading-page">
    <spinner-color />
  </div>
  <div v-else class="control-notification">
    <div class="title">
      <i>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 16.352 18.006"
        >
          <g
            id="Group_1960"
            data-name="Group 1960"
            transform="translate(-1.25 -1.25)"
          >
            <g
              id="Group_1961"
              data-name="Group 1961"
              transform="translate(2 2)"
            >
              <path
                id="Path_2396"
                data-name="Path 2396"
                d="M14.377,6.951a4.951,4.951,0,1,0-9.9,0C4.475,12.727,2,14.377,2,14.377H16.852s-2.475-1.65-2.475-7.426"
                transform="translate(-2 -2)"
                fill="none"
                stroke="#31006F"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                id="Path_2397"
                data-name="Path 2397"
                d="M19.395,40a1.65,1.65,0,0,1-2.855,0"
                transform="translate(-10.541 -24.322)"
                fill="none"
                stroke="#31006F"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
            </g>
          </g>
        </svg>
      </i>
      <div>
        <h4>التحكم في الإشعارات</h4>
        <p>
          يمكنك من هنا التحكم في الإشعارات التي ترغب في وصولها إليك بكل سهولة.
        </p>
      </div>
    </div>
    <div
      :class="item.status == 1 ? 'switch-content' : 'disabled-switch-content'"
      v-for="(item, index) in notification_control"
      :key="index"
    >
      <toggle-button
        color="#31006F"
        @change="onChangeEventHandler(item)"
        :value="item.status"
        :width="48"
        :height="32"
        :sync="true"
      />
      <div>
        <h6>{{ item.type_text }}</h6>
        <p>{{ item.type_description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SpinnerColor from "@/views/components/table-components/SpinnerColor2.vue";
export default {
  name: "controlNotification",
  data() {
    return {
      loading: false,
    };
  },
  components: {
    SpinnerColor,
  },
  computed: {
    ...mapGetters("dataList", ["notification_control"]),
  },
  methods: {
    ...mapActions("dataList", ["saveNotification", "controlNotification"]),
    async onChangeEventHandler(item) {
      let status;
      if (item.status) {
        status = 0;
      } else {
        status = 1;
      }
      await this.saveNotification({ type: item.type, status: status });
    },
  },
  async created() {
    this.loading = true;
    await this.controlNotification();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_setting.scss";
.loading-page {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
